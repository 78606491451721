var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row mb-6"},[_c('p',{staticClass:"text-2xl mb-6"},[_vm._v(" GA view IDs pour le département ")]),_c('v-autocomplete',{staticClass:"select-department ml-16 shrink",attrs:{"outlined":"","items":_vm.departmentList,"menu-props":{ maxHeight: '400', zIndex: 13 },"label":"Département","item-text":"name","item-value":"id"},on:{"change":_vm.getData},model:{value:(_vm.selectedDepartmentId),callback:function ($$v) {_vm.selectedDepartmentId=$$v},expression:"selectedDepartmentId"}})],1),_c('v-text-field',{attrs:{"label":"Search","dense":""},on:{"input":_vm.search},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}}),(_vm.filteredData.length !== 0)?_c('div',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Id")]),_c('th',{staticClass:"text-left"},[_vm._v("Nom du site (id)")]),_c('th',{staticClass:"text-left"},[_vm._v("Domaine")]),_c('th',{staticClass:"text-left"},[_vm._v("GA View Id par défaut")]),_c('th',{staticClass:"text-left"},[_vm._v(" CRM GA View Id "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s("<span>*<sup>?</sup><span>")}},'span',attrs,false),on))]}}],null,false,3276262887)},[_c('span',[_vm._v(" Les GA viewID spécifique en rouges montrent qu'ils sont en fait identiques à l'original, donc inutiles. ")])])],1),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.filteredData),function(item,i){return _c('tr',{key:item.department_site_ga_view_id_id},[_c('td',[_vm._v(_vm._s(item.department_site_ga_view_id_id))]),_c('td',[_c('b',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.site_id)+")")])]),_c('td',[_vm._v(_vm._s(item.domain))]),_c('td',[_vm._v(_vm._s(item.default_ga_view_id))]),_c('td',[(i == _vm.selectedIndex)?_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-text-field',{attrs:{"label":"Department GA view id"},model:{value:(item.input),callback:function ($$v) {_vm.$set(item, "input", $$v)},expression:"item.input"}}),_c('v-btn',{staticClass:"mx-2 align-self-center",attrs:{"data-index":i,"color":"primary","small":"","elevation":"4"},on:{"click":_vm.save}},[_vm._v(" OK ")]),_c('v-btn',{staticClass:"mx-2 align-self-center",attrs:{"data-index":i,"color":"primary","icon":"","fab":"","x-small":"","elevation":"4"},on:{"click":_vm.resetSelectedIndex}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")])],1)],1):_c('b',{class:item.default_ga_view_id == item.department_site_ga_view_id
                    ? 'red'
                    : 'primary--text'},[_vm._v(" "+_vm._s(item.department_site_ga_view_id)+" ")])]),_c('td',[(i != _vm.selectedIndex)?_c('div',[(
                    !item.department_site_ga_view_id_id ||
                    item.department_site_ga_view_id == '' ||
                    item.department_site_ga_view_id == null
                  )?_c('v-btn',{staticClass:"mx-2",attrs:{"data-index":i,"color":"primary","icon":"","fab":"","x-small":"","elevation":"4"},on:{"click":_vm.edit}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"data-index":i,"color":"primary","icon":"","fab":"","x-small":"","elevation":"4"},on:{"click":_vm.edit}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),(
                    item.department_site_ga_view_id_id &&
                    item.department_site_ga_view_id != '' &&
                    item.department_site_ga_view_id
                  )?_c('v-btn',{staticClass:"mx-2",attrs:{"data-index":i,"color":"error","icon":"","fab":"","x-small":"","elevation":"4"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1):_vm._e()],1):_vm._e()])])}),0)]},proxy:true}],null,false,3741885305)})],1):_c('div',[_vm._v(" "+_vm._s(_vm.noDataMessage)+" ")]),_c('v-snackbar',{attrs:{"timeout":2000,"top":"","color":_vm.snackbarColor},model:{value:(_vm.isSnackbarVisible),callback:function ($$v) {_vm.isSnackbarVisible=$$v},expression:"isSnackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialogConfirmVisible),callback:function ($$v) {_vm.dialogConfirmVisible=$$v},expression:"dialogConfirmVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.dialogConfirmTitle)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogConfirmText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":_vm.dialogConfirmCallback}},[_vm._v(" Oui ")]),_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialogConfirmVisible = false}}},[_vm._v(" Abandonner ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }